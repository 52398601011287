// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const crp: Topic = {
  topic: 'CRP',
  level: 'Advanced',
  totalQuestions: 60,
  totalScore: 300,
  totalTime: 360,
  questions: [
    {
      "question": "O mandato do Presidente da República tem a duração de quantos anos?",
      "choices": [
        "4",
        "5",
        "6",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5"
      ],
      "score": 10
    },
    {
      "question": "São elegíveis os cidadãos eleitores para Presidente da República, portugueses de origem, a partir de que idade?",
      "choices": [
        "Maior que 33",
        "Maior que 34",
        "Maior que 35",
        "Maior que 36"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Maior que 35"
      ],
      "score": 10
    },
    {
      "question": "Quantos mandatos consecutivos pode exercer o Presidente da República?",
      "choices": [
        "1",
        "2",
        "3",
        "Sem limite"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "2"
      ],
      "score": 10
    },
    {
      "question": "Quais são os atos legislativos existentes?",
      "choices": [
        "as leis, os decretos-leis e os decretos legislativos regionais",
        "as leis, os decretos-leis",
        "as leis, regulamentos e decretos legislativos regionais",
        "as leis, os decretos-leis, regulamentos e decretos legislativos regionais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "as leis, os decretos-leis e os decretos legislativos regionais"
      ],
      "score": 10
    },
    {
      "question": "Qual o número mínimo de deputados na AR?",
      "choices": [
        "160",
        "170",
        "180",
        "190"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "180"
      ],
      "score": 10
    },
    {
      "question": "Qual o número máximo de deputados na AR?",
      "choices": [
        "210",
        "220",
        "230",
        "240"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "230"
      ],
      "score": 10
    },
    {
      "question": "O Conselho de Ministros é constituído por:",
      "choices": [
        "Primeiro-Ministro, pelos Vice-Primeiros-Ministros, Secretários e Subsecretários de Estado.",
        "Primeiro-Ministro e pelos Ministros.",
        "Primeiro-Ministro, pelos Vice-Primeiros-Ministros, se os houver, e pelos Ministros.",
        "Primeiro-Ministro, Secretários e Subsecretários de Estado."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Primeiro-Ministro, pelos Vice-Primeiros-Ministros, se os houver, e pelos Ministros."
      ],
      "score": 10
    },
    {
      "question": "O Governo é responsável perante quem?",
      "choices": [
        "Presidente da República, Tribunais e a Assembleia da República",
        "Assembleia da República",
        "Presidente da República, Tribunais",
        "Presidente da República e a Assembleia da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Presidente da República e a Assembleia da República"
      ],
      "score": 10
    },
    {
      "question": "Qual é o órgão de condução da política geral do país e o órgão superior da administração pública?",
      "choices": [
        "Presidente da República",
        "Assembleia da República",
        "Governo",
        "Tribunais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Governo"
      ],
      "score": 10
    },
    {
      "question": "Qual o órgão superior do Ministério Público?",
      "choices": [
        "Presidente da República",
        "PGR (Procuradoria Geral da República)",
        "Assembleia da República",
        "Governo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "PGR (Procuradoria Geral da República)"
      ],
      "score": 10
    },
    {
      "question": "No continente quais são as autarquias locais?",
      "choices": [
        "Freguesias, os municípios e as regiões administrativas",
        "Freguesias e os municípios",
        "Municípios e as regiões administrativas",
        "Freguesias e as regiões administrativas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Freguesias, os municípios e as regiões administrativas"
      ],
      "score": 10
    },
    {
      "question": "Quem tem o poder de vetar?",
      "choices": [
        "Assembleia da República",
        "Governo",
        "Primeiro Ministro",
        "Presidente da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Presidente da República"
      ],
      "score": 10
    },
    {
      "question": "A câmara municipal é o órgão executivo colegial do freguesia.",
      "choices": [
        "Verdadeiro",
        "Verdadeiro",
        "Falso",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Cessa o dever de obediência sempre que o cumprimento das ordens ou instruções implique a prática de qualquer crime.",
      "choices": [
        "Verdadeiro",
        "Verdadeiro",
        "Falso",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A câmara municipal é:",
      "choices": [
        "O edifício do município",
        "O órgão executivo colegial do município",
        "O órgão executivo colegial do município e assembleia municipal",
        "O órgão municipal do concelho"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O órgão executivo colegial do município"
      ],
      "score": 10
    },
    {
      "question": "Os partidos políticos concorrem para a organização e para a expressão da vontade popular, no respeito pelos princípios da independência nacional:",
      "choices": [
        "Da unidade do Estado e da democracia política",
        "Da unidade do Estado e dos interesses dos partidos políticos",
        "Da democracia política e nos interesses dos partidos políticos e dos sindicatos",
        "Da unidade do Estado, da democracia política e no interesse dos partidos políticos e da associação nacional de municípios"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Da unidade do Estado e da democracia política"
      ],
      "score": 10
    },
    {
      "question": "Em Portugal os Órgãos de Soberania são:",
      "choices": [
        "A Assembleia da República, o Governo e os Tribunais",
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais",
        "O Presidente da República e a Assembleia da República",
        "A assembleia da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais"
      ],
      "score": 10
    },
    {
      "question": "O Órgão de Soberania que garante a independência nacional e a unidade do Estado é o Presidente da República?",
      "choices": [
        "Não, é o Governo",
        "É o Presidente da República e a Assembleia da República",
        "Sim, e representa a República Portuguesa",
        "A assembleia da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sim, e representa a República Portuguesa"
      ],
      "score": 10
    },
    {
      "question": "O Órgão de Soberania que assegura a condução da política geral do país é:",
      "choices": [
        "Assembleia da República",
        "O Governo",
        "Os órgãos de soberania em conjunto",
        "Os deputados"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Governo"
      ],
      "score": 10
    },
    {
      "question": "O Presidente da República é o Comandante Geral das Forças Armadas?",
      "choices": [
        "Sim, o Presidente da República é por inerência o Comandante Geral das Forças Armadas",
        "Não, é o Ministro da Defesa",
        "Sim, com a concordância dos Tribunais",
        "Não, é o Ministro da Administração Interna"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sim, o Presidente da República é por inerência o Comandante Geral das Forças Armadas"
      ],
      "score": 10
    },
    {
      "question": "A Assembleia da República tem função legislativa?",
      "choices": [
        "Não, são os Tribunais",
        "Sim, é o órgão legislativo por excelência",
        "Assembleia da República e os Tribunais",
        "Não, é o órgão executivo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sim, é o órgão legislativo por excelência"
      ],
      "score": 10
    },
    {
      "question": "Os direitos dos cidadãos são assegurados:",
      "choices": [
        "Pela Assembleia da República e pelos Tribunais",
        "Pelos os Tribunais",
        "Pelo Governo",
        "Pelos órgãos de soberania"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pelos os Tribunais"
      ],
      "score": 10
    },
    {
      "question": "O órgão de Soberania que garante o regular funcionamento das instituições democráticas é:",
      "choices": [
        "A Assembleia da República",
        "O Presidente da República",
        "O Governo e a Assembleia da República",
        "Os tribunais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República"
      ],
      "score": 10
    },
    {
      "question": "Os Órgãos de Soberania desempenham as suas funções de uma forma autónoma, não existindo entre eles vínculos hierárquicos?",
      "choices": [
        "Depende apenas do Presidente da República",
        "Não! Existem entre eles vínculos hierárquicos",
        "Sim, é o princípio da separação dos poderes",
        "Depende dos tribunais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sim, é o princípio da separação dos poderes"
      ],
      "score": 10
    },
    {
      "question": "Os Órgãos de Soberania que reprimem a violação da legalidade democrática são:",
      "choices": [
        "Os Tribunais e o a Assembleia da Republica",
        "Os Tribunais e o Presidente da República",
        "Os Tribunais",
        "A assembleia da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os Tribunais"
      ],
      "score": 10
    },
    {
      "question": "A bandeira nacional, símbolo da soberania nacional, foi instaurada:",
      "choices": [
        "Pela Revolução de 5 de outubro de 1974",
        "Pela Revolução de 5 de outubro de 1910",
        "Pela Revolução do 25 de abril de 1974",
        "Pelo Conselho da Revolução em 1975"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pela Revolução de 5 de outubro de 1910"
      ],
      "score": 10
    },
    {
      "question": "Quem pode ter a iniciativa de revisão constitucional?",
      "choices": [
        "Povo",
        "Presidente da república",
        "Deputados",
        "Ministros"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Deputados"
      ],
      "score": 10
    },
    {
      "question": "O que é um Habeas Corpus?",
      "choices": [
        "Pedido de indemnização por danos sofridos injustamente",
        "Pedido de prisão preventiva",
        "Pedido de reposição da garantia constitucional de liberdade",
        "Pedido de direitos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pedido de reposição da garantia constitucional de liberdade"
      ],
      "score": 10
    },
    {
      "question": "Quem representa a República Portuguesa?",
      "choices": [
        "O presidente da República",
        "O presidente da assembleia da República",
        "Presidente do Tribunal Constitucional",
        "Primeiro-Ministro"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O presidente da República"
      ],
      "score": 10
    },
    {
      "question": "Por quantos juízes é composto o Tribunal Constitucional?",
      "choices": [
        "10",
        "6",
        "13",
        "14"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "13"
      ],
      "score": 10
    },
    {
      "question": "Quantos Representantes da República tem cada região autónoma?",
      "choices": [
        "2",
        "3",
        "4",
        "1"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1"
      ],
      "score": 10
    },
    {
      "question": "Uma sessão legislativa tem a duração de:",
      "choices": [
        "1 ano",
        "1 ano e meio",
        "2 anos",
        "3 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1 ano"
      ],
      "score": 10
    },
    {
      "question": "A Constituição da República Portuguesa pode ser revista, quantos anos após a data da publicação da última lei de revisão ordinária?",
      "choices": [
        "2 anos",
        "3 anos",
        "4 anos",
        "5 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5 anos"
      ],
      "score": 10
    },
    {
      "question": "O conselho das forças armadas é presidido pelo:",
      "choices": [
        "Presidente da República e com membros eleitos pela Assembleia da república",
        "Ministro da defesa",
        "Ministro da administração Interna",
        "Pelo Governo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Presidente da República e com membros eleitos pela Assembleia da república"
      ],
      "score": 10
    },
    {
      "question": "Em relação à soberania e legalidade:",
      "choices": [
        "A soberania, una e indivisível, reside no povo, que a exerce segundo as formas previstas na Constituição",
        "O Estado subordina-se à Constituição e funda-se na legalidade democrática",
        "A validade das leis e dos demais actos do Estado, das regiões autónomas, do poder local e de quaisquer outras entidades públicas depende da sua conformidade com a Constituição",
        "Todas as respostas anteriores estão corretas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as respostas anteriores estão corretas"
      ],
      "score": 10
    },
    {
      "question": "Qual/quais o(s) símbolo(s) da soberania Portuguesa?",
      "choices": [
        "Bandeira nacional e o hino",
        "O hino",
        "A bandeira nacional",
        "Nenhuma resposta anterior está correta"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A bandeira nacional"
      ],
      "score": 10
    },
    {
      "question": "Há a suspensão dos direitos dos cidadãos quando:",
      "choices": [
        "Os órgãos de soberania assim o declararem",
        "Quando altera o estado de emergência",
        "Quando o primeiro-ministro assim o entender",
        "Quando o cidadão não o merecer"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Quando altera o estado de emergência"
      ],
      "score": 10
    },
    {
      "question": "A prisão preventiva será submetida, no prazo máximo de:",
      "choices": [
        "48 horas",
        "24 horas",
        "72 horas",
        "96 horas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "48 horas"
      ],
      "score": 10
    },
    {
      "question": "Quais são os órgãos de soberania da República Portuguesa?",
      "choices": [
        "O Presidente da República, a Assembleia da República e o Governo",
        "O Presidente da República, a Assembleia da República, o Governo e os Deputados",
        "O Presidente da República, a Assembleia da República, o Governo e as Comissões",
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais"
      ],
      "score": 10
    },
    {
      "question": "Os Deputados não respondem",
      "choices": [
        "criminal ou disciplinarmente pelos votos e opiniões que emitirem no exercício das suas funções",
        "disciplinarmente pelos votos e opiniões que emitirem no exercício das suas funções",
        "civil, criminal, contraordenacional ou disciplinarmente pelos votos e opiniões que emitirem no exercício das suas funções",
        "civil, criminal ou disciplinarmente pelos votos e opiniões que emitirem no exercício das suas funções"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "civil, criminal ou disciplinarmente pelos votos e opiniões que emitirem no exercício das suas funções"
      ],
      "score": 10
    },
    {
      "question": "O Conselho de Ministros é constituído",
      "choices": [
        "pelo Primeiro-Ministro, pelos Vice-Primeiros-Ministros, se os houver, e pelos Ministros",
        "pelo Primeiro-Ministro, pelos Ministros e Secretários de Estado",
        "pelo Primeiro-Ministro e pelos Ministros",
        "pelo Primeiro-Ministro, pelos Ministros, Secretários e Subsecretários de Estado"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "pelo Primeiro-Ministro, pelos Vice-Primeiros-Ministros, se os houver, e pelos Ministros"
      ],
      "score": 10
    },
    {
      "question": "Compete ao Governo, no exercício de funções legislativas",
      "choices": [
        "fazer leis e decretos-lei em matérias não reservadas à Assembleia da República",
        "fazer decretos-lei em matérias não reservadas à Assembleia da República e ao Governo",
        "fazer regulamentos em matérias não reservadas à Assembleia da República",
        "fazer decretos-lei em matérias não reservadas à Assembleia da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "fazer decretos-lei em matérias não reservadas à Assembleia da República"
      ],
      "score": 10
    },
    {
      "question": "A câmara municipal é",
      "choices": [
        "o edifício do município",
        "o órgão executivo colegial do município",
        "o órgão executivo colegial do município e assembleia municipal",
        "o órgão municipal do concelho"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "o órgão executivo colegial do município"
      ],
      "score": 10
    },
    {
      "question": "Portugal é uma República soberana",
      "choices": [
        "baseada na vontade individual e empenhada na construção de uma sociedade livre e justa para a maioria da população",
        "baseada na dignidade da pessoa humana e na vontade dos partidos políticos e associações sindicais e empenhada na construção de uma sociedade livre, justa e solidária",
        "baseada na dignidade da pessoa humana e na vontade popular e empenhada na construção de uma sociedade livre, justa e solidária",
        "baseada na dignidade da pessoa humana, na vontade dos deputados e dos representantes das associações sindicais e empenhada na construção de uma sociedade livre e solidária"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "baseada na dignidade da pessoa humana e na vontade popular e empenhada na construção de uma sociedade livre, justa e solidária"
      ],
      "score": 10
    },
    {
      "question": "Os partidos políticos concorrem para a organização e para a expressão da vontade popular, no respeito pelos princípios da independência nacional",
      "choices": [
        "da unidade do Estado e da democracia política",
        "da unidade do Estado e dos interesses dos partidos políticos",
        "da democracia política e nos interesses dos partidos políticos e dos sindicatos",
        "da unidade do Estado, da democracia política e no interesse dos partidos políticos e da associação nacional de municípios"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "da unidade do Estado e da democracia política"
      ],
      "score": 10
    },
    {
      "question": "Os filhos não podem ser separados dos pais, salvo quando estes não cumpram",
      "choices": [
        "os seus deveres conjugais para com eles e sempre mediante decisão do conselho de família",
        "os seus deveres fundamentais para com eles e sempre mediante decisão judicial",
        "os seus deveres fundamentais para com eles e sempre mediante decisão favorável do conselho de família",
        "os seus deveres fundamentais para com eles e sempre mediante decisão administrativa"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "os seus deveres fundamentais para com eles e sempre mediante decisão judicial"
      ],
      "score": 10
    },
    {
      "question": "A detenção será submetida, no prazo máximo de",
      "choices": [
        "24 horas",
        "48 horas",
        "72 horas",
        "8 horas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "48 horas"
      ],
      "score": 10
    },
    {
      "question": "Têm direito de sufrágio todos os cidadãos",
      "choices": [
        "maiores de 21 anos",
        "maiores de 14 anos",
        "maiores de 16 anos",
        "maiores de 18 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "maiores de 18 anos"
      ],
      "score": 10
    },
    {
      "question": "A Assembleia Legislativa é eleita por",
      "choices": [
        "sufrágio universal, indireto e secreto, de harmonia com o princípio da representação proporcional",
        "sufrágio universal, direto e público, de harmonia com o princípio da representação proporcional",
        "sufrágio universal, direto e secreto, de harmonia com o princípio da representação proporcional",
        "sufrágio universal, indireto e público, de harmonia com o princípio da representação proporcional"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "sufrágio universal, direto e secreto, de harmonia com o princípio da representação proporcional"
      ],
      "score": 10
    },
    {
      "question": "A constituição da república portuguesa de 1976 (atual constituição portuguesa) foi redigida",
      "choices": [
        "Pela assembleia constituinte nomeada",
        "Por um comitê de peritos",
        "Pelo governo da república",
        "Pela assembleia constituinte eleita"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pela assembleia constituinte eleita"
      ],
      "score": 10
    },
    {
      "question": "A assembleia da república tem",
      "choices": [
        "O mínimo de 200 e o máximo de 230 deputados, nos termos da lei eleitoral",
        "O máximo de 250 deputados, nos termos da lei eleitoral",
        "O mínimo de 180 e o máximo de 280 deputados, nos termos da lei eleitoral",
        "O mínimo de 180 e o máximo de 230 deputados, nos termos da lei eleitoral"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O mínimo de 180 e o máximo de 230 deputados, nos termos da lei eleitoral"
      ],
      "score": 10
    },
    {
      "question": "A legislatura da assembleia da república tem duração de",
      "choices": [
        "5 sessões legislativas",
        "4 sessões legislativa",
        "4 anos civis",
        "5 anos civis"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "4 sessões legislativa"
      ],
      "score": 10
    },
    {
      "question": "O governo é constituído",
      "choices": [
        "Pelo primeiro-ministro, pelos ministros e pelos secretários e subsecretários de estado",
        "Pelos ministros e pelos secretários e subsecretários de estado",
        "Pelo primeiro ministro e pelos secretários e subsecretários de estado",
        "Pelo primeiro ministro e subsecretários de estado"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pelo primeiro-ministro, pelos ministros e pelos secretários e subsecretários de estado"
      ],
      "score": 10
    },
    {
      "question": "A interpretação dos preceitos constitucionais relativos aos direitos fundamentais, deve ser feita",
      "choices": [
        "De harmonia com os princípios gerais de direito",
        "De harmonia com a Declaração Universal dos Direitos Humanos",
        "De harmonia com a Declaração Europeia dos Direitos Humanos",
        "De harmonia com a Convenção Europeia dos Direitos Humanos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De harmonia com a Declaração Universal dos Direitos Humanos"
      ],
      "score": 10
    },
    {
      "question": "Os direitos, liberdades e garantias podem",
      "choices": [
        "Ser objeto de restrição nos casos previstos na CRP e na Lei",
        "Ser sempre objeto de restrição",
        "Nunca podem ser objeto de restrição",
        "Ser objeto de restrição mas apenas, para salvaguarda doutros direitos e deveres e nos casos em que a constituição o preveja"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ser objeto de restrição mas apenas, para salvaguarda doutros direitos e deveres e nos casos em que a constituição o preveja"
      ],
      "score": 10
    },
    {
      "question": "Segundo a CRP, os Direitos Fundamentais são",
      "choices": [
        "Aqueles que se encontram plasmados na CRP, os reconhecidos pelo Direito Internacional, bem como aqueles que se encontram em legislação ordinária",
        "Aqueles que se encontram plasmados na CRP e na legislação ordinária",
        "Aqueles que se encontram plasmados na CRP, na legislação extravagante e todos os reconhecidos pelo Direito Internacional Privado",
        "Aqueles que foram convencionados pelo Estado Português e que tenham força de reserva de lei"
      ],
      "type": "MCQs",
      "correctAnswers": [],
      "score": 10
    },
    {
      "question": "O Princípio da Proporcionalidade ou da Proibição do Excesso tem como requisitos",
      "choices": [
        "A necessidade, e a clareza das ações por parte dos Órgãos do Governo",
        "A precisão e a determinabilidade das leis, quanto aos seus limites formais e materiais",
        "Proporcionalidade quanto à Proteção da Confiança em sentido estrito, bem como a exigibilidade de uma atuação de acordo com a lei fundamental",
        "A necessidade, exigibilidade; adequação e proporcionalidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A necessidade, exigibilidade; adequação e proporcionalidade"
      ],
      "score": 10
    },
    {
      "question": "A declaração do estado de sítio ou do estado de emergência terá que",
      "choices": [
        "Ser fundamentada, especificar os Direitos Liberdades e Garantias que são suspensos, especificar o tempo de duração, que não poderá exceder 15 dias",
        "Ser fundamentada, especificar os D.L.G. que são suspensos",
        "Especificar o tempo de duração que não poderá exceder 15 dias",
        "Ser publicada no Diário da República, especificar os D.L.G. que são suspensos, e a sua duração que não poderá exceder 15 dias"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ser fundamentada, especificar os Direitos Liberdades e Garantias que são suspensos, especificar o tempo de duração, que não poderá exceder 15 dias"
      ],
      "score": 10
    },
    {
      "question": "Segundo a alínea a) do n.o 2 do artigo 64.o da CRP, o direito à proteção da saúde é realizado",
      "choices": [
        "Através de um serviço nacional de saúde universal e geral e, tendo em conta as condições económicas e sociais dos cidadãos, gratuito",
        "Através de um serviço nacional de saúde universal e geral e, tendo em conta as condições",
        "Através de um serviço nacional de saúde local e restrito e, tendo em conta as condições económicas e sociais dos cidadãos, tendencialmente gratuito",
        "Através de um serviço nacional de saúde local e restrito e, tendo em conta as condições económicas e sociais dos cidadãos, gratuito"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Através de um serviço nacional de saúde universal e geral e, tendo em conta as condições"
      ],
      "score": 10
    },
    {
      "question": "A Constituição da República Portuguesa confere aos jovens o gozo de proteção especial para efetivação dos seus direitos económicos, sociais e culturais, nomeadamente",
      "choices": [
        "No ensino, na formação profissional e na cultura",
        "Na educação física e no desporto",
        "No aproveitamento dos tempos livres",
        "Todas as opções estão corretas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as opções estão corretas"
      ],
      "score": 10
    }
  ],
}
