import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBq7iSlgO8YiN2Y7n39k5goghAZd3Z5s-U",
  authDomain: "euqueroentrar-web.firebaseapp.com",
  projectId: "euqueroentrar-web",
  storageBucket: "euqueroentrar-web.appspot.com",
  messagingSenderId: "669740018060",
  appId: "1:669740018060:web:f17eaf1336720c8aae8ea9",
  measurementId: "G-H65ND98J67"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
