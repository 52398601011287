import React, { useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider, createTheme, Box, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Main from './components/Main';
import NavBar from './components/NavBar';
import QuizProvider from './context/QuizContext';
import { GlobalStyles } from './styles/Global';
import { themes } from './styles/Theme';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './context/PrivateRoute';
import Login from './components/Login';
import Profile from './components/Profile';
import Purchase from './components/Purchase';
import Register from './components/Register';
import Psp from './components/pages/psp';
import Gnr from './components/pages/gnr';

function App() {
  const [currentTheme, setCurrentTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'light';
  });

  const toggleTheme = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setCurrentTheme(checked ? 'dark' : 'light');
    localStorage.setItem('theme', checked ? 'dark' : 'light');
  };

  const theme = currentTheme === 'light' ? themes.light : themes.dark;
  const muiTheme = createTheme({
    palette: {
      mode: currentTheme as 'light' | 'dark',
    },
  });

  return (
    <StyledThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <GlobalStyles />
        <AuthProvider>
          <QuizProvider>
            <Router>
              <NavBar toggleTheme={toggleTheme} currentTheme={currentTheme} handlePurchaseNavigation={() => {}} />
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/profile"
                    element={
                      <PrivateRoute>
                        <Profile />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/purchase"
                    element={
                      <PrivateRoute>
                        <Purchase />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/psp"
                    element={
                      <PrivateRoute>
                        <Psp />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/gnr"
                    element={
                      <PrivateRoute>
                        <Gnr />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <Main currentTheme={currentTheme} toggleTheme={toggleTheme} />
                      </PrivateRoute>
                    }
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                  <Route path="/register" element={<Register />} />
                </Routes>
            </Router>
          </QuizProvider>
        </AuthProvider>
      </MuiThemeProvider>
    </StyledThemeProvider>
  );
}

export default App;
