import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { AppLogo, Refresh } from '../../config/icons';
import { useQuiz } from '../../context/QuizContext';
import { device } from '../../styles/BreakPoints';
import { Flex, LogoContainer, ResizableBox } from '../../styles/Global';
import { refreshPage } from '../../utils/helpers';

import Button from '../ui/Button';
import CodeSnippet from '../ui/CodeSnippet';
import QuizImage from '../ui/QuizImage';
import ResultOverview from './ResultOverview';
import RightAnswer from './RightAnswer';

const ResultScreenContainer = styled.div`
  max-width: 900px;
  margin: 30px auto;
  padding-top: 30px;
  @media ${device.md} {
    width: 90%;
    margin: 30px auto;
    padding-top: 300px;
  }
`;

const InnerContainer = styled.div`
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 40px 90px 90px 90px;
  @media ${device.md} {
    padding: 15px;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  @media ${device.md} {
    flex-direction: column;
  }
`;

const QuestionNumber = styled.h6`
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 500;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.primaryText};
`;

const QuestionStyle = styled.span`
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 500;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.primaryText};
  margin-bottom: 20px;
  @media ${device.md} {
    margin-bottom: 10px;
  }
`;

interface AnswerProps {
  correct?: boolean;
  wrong?: boolean;
}

const Answer = styled.li<AnswerProps>`
  border: 1px solid ${({ theme }) => theme.colors.border};
  width: 90%;
  @media ${device.md} {
    width: 100%;
  }
  background: ${({ theme }) => theme.colors.answerBg};
  border-radius: 16px;
  font-size: clamp(16px, 5vw, 18px);
  font-weight: 600;
  padding: 15px;
  color: ${({ theme }) => theme.colors.secondaryText};
  margin-top: clamp(13px, calc(10px + 6 * ((100vw - 600px) / 1320)), 16px);

  ${({ correct }) =>
    correct &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.success};
      background-color: ${({ theme }) => theme.colors.successLight};
    `}

  ${({ wrong }) =>
    wrong &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.danger};
      background-color: ${({ theme }) => theme.colors.dangerLight};
    `}

  span {
    margin-right: 14px;
  }

  @media ${device.md} {
    font-weight: 400;
  }
`;

const Score = styled.span<{ right: boolean }>`
  font-weight: 500;
  font-size: 16px;
  color: ${({ right, theme }) =>
    right ? `${theme.colors.success}` : `${theme.colors.danger}`};
  margin-top: 4px;
  @media ${device.md} {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 10px;
  }
`;

const ResultScreen: FC = () => {
  const { result } = useQuiz();

  const onClickRetry = () => {
    refreshPage();
  };

  const sendReport = async (
    question: string,
    choices: string[],
    correctAnswers: string[],
    selectedAnswer: string[]
  ) => {
    const formData = new FormData();
    formData.append('entry.1044558321', question); // Campo da pergunta
    formData.append('entry.1271381232', choices[0] || ''); // Campo Answer 1
    formData.append('entry.241690610', choices[1] || ''); // Campo Answer 2
    formData.append('entry.1024527538', choices[2] || ''); // Campo Answer 3
    formData.append('entry.1818667854', choices[3] || ''); // Campo Answer 4
    formData.append('entry.2010802445', correctAnswers.join(', ')); // Campo Correct Answer
    formData.append('entry.7890123456', selectedAnswer.join(', ')); // Campo Selected Answer

    try {
      const response = await fetch(
        'https://docs.google.com/forms/d/e/1FAIpQLSc27AYrMFbQVc84N8XccVu75g5HJjK-0nrPvU76qJ7uH9qSvQ/formResponse',
        {
          method: 'POST',
          body: formData,
          mode: 'no-cors',
        }
      );
      if (response) {
        alert('Report submitted successfully');
      }
    } catch (error) {
      console.error('Error submitting report', error);
    }
  };

  return (
    <ResultScreenContainer>
      <LogoContainer>
        <AppLogo />
      </LogoContainer>
      <InnerContainer>
        <ResultOverview result={result} />
        {result.map(
          (
            {
              question,
              choices,
              code,
              image,
              correctAnswers,
              selectedAnswer,
              score,
              isMatch,
            },
            index: number
          ) => {
            return (
              <QuestionContainer key={question}>
                <ResizableBox width="90%">
                  <Flex gap="4px">
                    <QuestionNumber>{`${index + 1}. `}</QuestionNumber>
                    <QuestionStyle>{question}</QuestionStyle>
                  </Flex>
                  <div>
                    {code && <CodeSnippet code={code} language="javascript" />}
                    {image && <QuizImage image={image} />}
                    <ul>
                      {choices.map((ans: string, index: number) => {
                        const label = String.fromCharCode(65 + index);
                        const correct =
                          selectedAnswer.includes(ans) &&
                          correctAnswers.includes(ans);
                        const wrong =
                          selectedAnswer.includes(ans) &&
                          !correctAnswers.includes(ans);

                        return (
                          <Answer key={ans} correct={correct} wrong={wrong}>
                            <span>{label}.</span>
                            {ans}
                          </Answer>
                        );
                      })}
                    </ul>
                    {!isMatch && (
                      <RightAnswer
                        correctAnswers={correctAnswers}
                        choices={choices}
                      />
                    )}
                    <Button
                      text="Report"
                      onClick={() =>
                        sendReport(
                          question,
                          choices,
                          correctAnswers,
                          selectedAnswer
                        )
                      }
                    />
                  </div>
                </ResizableBox>
                <Score right={isMatch}>{`Score ${isMatch ? score : 0}`}</Score>
              </QuestionContainer>
            );
          }
        )}
      </InnerContainer>
      <Flex flxEnd>
        <Button
          text="RETRY"
          onClick={onClickRetry}
          icon={<Refresh />}
          iconPosition="left"
          bold
        />
      </Flex>
    </ResultScreenContainer>
  );
};

export default ResultScreen;
