// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const dudh: Topic = {
  topic: 'DUDH',
  level: 'Advanced',
  totalQuestions: 60,
  totalScore: 300,
  totalTime: 360,
  questions: [
    {
      "question": "Nos termos do artigo 14º da DUDH todo ser humano, vítima de perseguição, tem o direito de procurar e de gozar de asilo em outros países, exceto por crimes",
      "choices": [
        "De direito comum ou por todos os atos contrários aos objetivos e princípios das Nações Unidas.",
        "Políticos",
        "Cometidos contra Estados ditatoriais com o fim de os derrubar",
        "De direito comum ou por atos segundo os objetivos e princípios das Nações Unidas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De direito comum ou por todos os atos contrários aos objetivos e princípios das Nações Unidas."
      ],
      "score": 10
    },
    {
      "question": "Nos termos do artigo 23º da DUDH todo ser humano que trabalha tem direito",
      "choices": [
        "A uma remuneração justa e satisfatória que lhe assegure, assim como à sua família, uma existência compatível com a sua condição social",
        "A uma remuneração justa e satisfatória que lhe assegure, assim como à sua família uma existência que lhe permita no mínimo alimentar-se",
        "A uma remuneração justa e satisfatória que lhe assegure, assim como à sua família, uma existência compatível com os padrões sociais mais elevados da sociedade",
        "A uma remuneração justa e satisfatória que lhe assegure, assim como à sua família, uma existência compatível com a dignidade humana"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A uma remuneração justa e satisfatória que lhe assegure, assim como à sua família, uma existência compatível com a dignidade humana"
      ],
      "score": 10
    },
    {
      "question": "Podemos considerar antecessor da DUDH o cilindro",
      "choices": [
        "De Ciro",
        "Do imperador César Augustus",
        "Do imperador Napoleão Bonaparte",
        "De Viriato da Lusitânia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De Ciro"
      ],
      "score": 10
    },
    {
      "question": "A Declaração Universal dos Direitos Humanos (DUDH) foi proclamada pela Assembleia Geral das Nações Unidas em:",
      "choices": [
        "Paris",
        "Nova Iorque",
        "Londres",
        "Lisboa"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Paris"
      ],
      "score": 10
    },
    {
      "question": "A Declaração Universal dos Direitos Humanos (DUDH), foi proclamada pela Assembleia Geral das Nações Unidas em:",
      "choices": [
        "10 de Dezembro de 1948",
        "10 de Dezembro de 1958",
        "10 de Dezembro de 1968",
        "10 de Outubro de 1948"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "10 de Dezembro de 1948"
      ],
      "score": 10
    },
    {
      "question": "Declaração Universal dos Direitos Humanos (DUDH) das Nações Unidas foi proclamada:",
      "choices": [
        "Na sequência das experiências catastróficas da primeira Guerra Mundial",
        "Na sequência das experiências tenebrosas do Holocausto e da segunda Guerra Mundial",
        "Na sequência da guerra dos 100 anos",
        "Na sequência do armistício que pôs fim à primeira Grande Guerra"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Na sequência das experiências tenebrosas do Holocausto e da segunda Guerra Mundial"
      ],
      "score": 10
    },
    {
      "question": "Os direitos ínsitos na Declaração Universal dos Direitos Humanos (DUDH) são:",
      "choices": [
        "Regionais e divisíveis",
        "Universais e divisíveis",
        "Universais e indivisíveis",
        "Universais e transmissíveis"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Universais e indivisíveis"
      ],
      "score": 10
    },
    {
      "question": "A Declaração Universal dos Direitos Humanos (DUDH) foi proclamada:",
      "choices": [
        "Pelo Conselho Permanente das Nações Unidas",
        "Pelo Comitê Permanente de Prevenção Contra a Tortura das Nações Unidas",
        "Pela Assembleia Geral das Nações Unidas",
        "Pelo Secretário-geral das Nações Unidas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pela Assembleia Geral das Nações Unidas"
      ],
      "score": 10
    },
    {
      "question": "Todos os seres humanos nascem:",
      "choices": [
        "Livres e iguais nos seus deveres",
        "Livres e iguais em dignidade e em direitos",
        "Livres e iguais em dignidade e com alguns direitos",
        "Livres e iguais apenas em dignidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Livres e iguais em dignidade e em direitos"
      ],
      "score": 10
    },
    {
      "question": "Todos os indivíduos têm direito",
      "choices": [
        "Ao reconhecimento junto da sua Comunidade da sua personalidade jurídica",
        "Ao reconhecimento por parte do Governo da sua personalidade jurídica",
        "Ao reconhecimento em todos os lugares da sua personalidade jurídica",
        "Ao reconhecimento perante os Tribunais da sua personalidade jurídica"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ao reconhecimento em todos os lugares da sua personalidade jurídica"
      ],
      "score": 10
    },
    {
      "question": "Ninguém pode ser arbitrariamente:",
      "choices": [
        "Preso",
        "Detido",
        "Exilado",
        "Todas as opções estão corretas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as opções estão corretas"
      ],
      "score": 10
    },
    {
      "question": "Toda a pessoa tem o direito de:",
      "choices": [
        "Livremente circular e escolher a sua residência no interior de um Estado",
        "Livremente circular e escolher a sua residência em qualquer local",
        "Circular com a condicionante imposta por cada Estado e aí escolher a sua residência",
        "Com exceção dos criminosos, livremente circular e escolher a sua residência no interior de um Estado"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Livremente circular e escolher a sua residência no interior de um Estado"
      ],
      "score": 10
    },
    {
      "question": "Toda a pessoa, individual ou coletivamente:",
      "choices": [
        "Tem direito ao respeito",
        "Tem direito a uma casa",
        "Tem direito à propriedade",
        "Tem direito de pronúncia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tem direito à propriedade"
      ],
      "score": 10
    },
    {
      "question": "Nos termos do Artigo 5.o da Declaração Universal dos Direitos Humanos",
      "choices": [
        "apenas em caso de terrorismo os cidadãos poderão ser submetidos a tortura e a penas ou tratamentos cruéis, desumanos ou degradantes",
        "qualquer cidadão poderá ser submetido a tortura nos casos de criminalidade violenta ou altamente organizada com o único objetivo de obter prova",
        "ninguém será submetido a tortura nem a penas ou tratamentos cruéis, desumanos ou degradantes",
        "apenas em caso de terrorismo os cidadãos poderão ser submetidos a tortura mas não a penas ou tratamentos cruéis, desumanos ou degradantes"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "ninguém será submetido a tortura nem a penas ou tratamentos cruéis, desumanos ou degradantes"
      ],
      "score": 10
    },
    {
      "question": "Todos os seres humanos nascem livres e com os mesmos direitos",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Toda a gente tem direito, à vida, à liberdade e à segurança pessoal",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A escravatura ou servidão são permitidas",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Ninguém pode ser submetido a tortura, penas ou tratamentos cruéis, desumanos ou degradantes",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Nenhuma pessoa tem direito à proteção contra qualquer discriminação ou incitamento",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Ninguém pode ser arbitrariamente preso, detido ou exilado",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Toda a pessoa é culpada, até a sua inocência ser legalmente provada",
      "choices": [
        "Falso",
        "Verdadeiro"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Nenhum indivíduo tem direito à propriedade",
      "choices": [
        "Falso",
        "Verdadeiro"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Toda a pessoa tem o direito a ter uma nacionalidade ou a mudar a sua",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Apenas os indivíduos de maior idade tem direito à liberdade de opinião e de expressão",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Toda a pessoa tem direito ao trabalho e à proteção contra o desemprego",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Nenhuma pessoa tem direito ao repouso e a férias de trabalho periódicas pagas",
      "choices": [
        "Falso",
        "Verdadeiro"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Toda a pessoa tem o direito de matar",
      "choices": [
        "Falso",
        "Verdadeiro"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Todo o indivíduo tem direito à proteção de interesses morais e materiais",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Ninguém é obrigado a entregar-se a alguma atividade ou a praticar algum ato, que destrua os direitos e liberdades",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Apenas as pessoas ricas tem direito à educação, no ensino elementar fundamental, que é obrigatório e gratuito",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    }
  ],
}
