import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase/firebaseConfig';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Container, TextField, Button, Typography, Paper, Alert, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const [userData, setUserData] = useState({
    name: '',
    address: '',
    phone: '',
    age: ''
  });
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ visible: false, severity: '', message: '' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = auth.currentUser.uid;
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  function alert_message(){
    setAlert({ visible: true, severity: 'success', message: 'Data saved successfully!' });
      setTimeout(() => {
        setAlert({ visible: false, severity: '', message: '' });
      }, 3000);
  }

  const handleSave = async () => {
    try {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, "users", userId);
      alert_message()
    } catch (error) {
      console.error("Error updating user data: ", error);
      setAlert({ visible: true, severity: 'error', message: 'Failed to save data.' });
      setTimeout(() => {
        setAlert({ visible: false, severity: '', message: '' });
      }, 3000);
    }
  };

  if (loading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <> 
      {/* ALERT MSG */}
      {alert.visible && (
        <Box sx={{ width: '50%', position: 'fixed', top: 100, left: '50%', transform: 'translateX(-50%)', zIndex: 1200 }}>
          <Alert severity={alert.severity} variant="filled">
            {alert.message}
          </Alert>
        </Box>
      )}

      {/* ALERT MSG */}


        <Container maxWidth="sm" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', paddingTop: '4rem' }}>
        <Paper style={{ padding: '2rem', width: '100%' }}>
          <Typography variant="h4" gutterBottom>
            Manage Your Data
          </Typography>
          <TextField
            label="Full Name"
            name="name"
            type="text"
            fullWidth
            margin="normal"
            value={userData.name}
            onChange={handleChange}
          />
          <TextField
            label="Address"
            name="address"
            type="text"
            fullWidth
            margin="normal"
            value={userData.address}
            onChange={handleChange}
          />
          <TextField
            label="Phone Number"
            name="phone"
            type="text"
            fullWidth
            margin="normal"
            value={userData.phone}
            onChange={handleChange}
          />
          <TextField
            label="Age"
            name="age"
            type="number"
            fullWidth
            margin="normal"
            value={userData.age}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{ marginTop: '20px' }}
          >
            Save
          </Button>
        </Paper>
      </Container>
    </>
  );
};

export default Profile;
