// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const psp_testeGeral_1: Topic = {
  topic: 'Teste Geral (1) - PSP',
  level: 'Advanced',
  totalQuestions: 60,
  totalScore: 300,
  totalTime: 360,
  questions: [
    {
      "question": "Das frases seguintes, apenas uma não tem erros ortográficos. Qual?",
      "choices": [
        "“à menos menos mulheres na polícia do que nas outras forças de segurança.”",
        "“há polícias que ao inicio do estagio preferem estar longe da residencia.”",
        "“ah polícias com muita experiencia no âmbito dos furtos.”",
        "“à inexperiência junta-se humildade e prudência, aconselhava-me um polícia aposentado.”"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "“à inexperiência junta-se humildade e prudência, aconselhava-me um polícia aposentado.”"
      ],
      "score": 10
    },
    {
      "question": "Considere a seguinte frase: “Havia intenção benévola”. Em qual das seguintes opções a frase se encontra, corretamente escrita, no plural.",
      "choices": [
        "“havia intenções benévolas.”",
        "“haviam intenções benévolas.”",
        "“haviam intenções benévola.”",
        "“houveram intenções benévolas.”"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "“havia intenções benévolas.”"
      ],
      "score": 10
    },
    {
      "question": "Qual das frases seguintes tem a forma verbal adequada?",
      "choices": [
        "no mês transato a PSP divulgará o número de queixas apresentadas.",
        "eu iniciava a sessão fotográfica, ao mesmo tempo que tu abririas o livro.",
        "atualmente os estudantes possuíam livros editados eletronicamente.",
        "no ano passado, nós respondemos a um questionário sobre a avaliação."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "no ano passado, nós respondemos a um questionário sobre a avaliação."
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes palavras contém erro de acentuação?",
      "choices": [
        "Comboio",
        "Leem",
        "Doi",
        "Joia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Doi"
      ],
      "score": 10
    },
    {
      "question": "O que é um desiderato?",
      "choices": [
        "Objetivo que se deseja atingir",
        "Algo que não pertence ao espaço sideral",
        "Rocha resultante de uma aglomeração de pequenas rochas e sem qualquer humidade na sua constituição",
        "Um desperdício de uma Siderurgia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Objetivo que se deseja atingir"
      ],
      "score": 10
    },
    {
      "question": "O que significa a palavra delapidar?",
      "choices": [
        "Corrigir eventuais erros, tornar mais perfeito",
        "Desperdiçar ou destruir algo ou alguém",
        "Construir lápides, arte de construir túmulos",
        "Bondade e simplicidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Desperdiçar ou destruir algo ou alguém"
      ],
      "score": 10
    },
    {
      "question": "O que significa a palavra verborreia?",
      "choices": [
        "Condição de quem sofre de infeções nos órgãos da audição",
        "Alguém que usa muitas palavras, mas com pouco conteúdo",
        "Uma confusão de ideais que podem contradizer-se",
        "Condição de quem sofre de infeções nos órgãos da visão"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Alguém que usa muitas palavras, mas com pouco conteúdo"
      ],
      "score": 10
    },
    {
      "question": "O que significa a palavra sumidade?",
      "choices": [
        "Eventualidade de desaparecer sem deixar rasto",
        "Qualidade do conteúdo de um trabalho científico",
        "Alguém que é ilustre ou importante",
        "Alguém que é um esbanjador, que gasta me demasia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Alguém que é ilustre ou importante"
      ],
      "score": 10
    },
    {
      "question": "O que significa a palavra 'enfezado'?",
      "choices": [
        "Forte",
        "Cansado",
        "Definhado",
        "Corado"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Definhado"
      ],
      "score": 10
    },
    {
      "question": "De entre as hipóteses apresentadas indique o sinônimo de cogitado:",
      "choices": [
        "Vomitato",
        "Pensado",
        "Esperado",
        "Mostrado"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pensado"
      ],
      "score": 10
    },
    {
      "question": "Indique a palavra que não contém erro ortográfico:",
      "choices": [
        "Extrapolar",
        "Estrapular",
        "Extrapular",
        "Extarpolar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Extrapolar"
      ],
      "score": 10
    },
    {
      "question": "O romance “Equador” tem como autor",
      "choices": [
        "José Saramago",
        "Miguel Esteves Cardoso",
        "António Lobo Antunes",
        "Miguel Sousa Tavares"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Miguel Sousa Tavares"
      ],
      "score": 10
    },
    {
      "question": "A Arábia Saudita fica situada",
      "choices": [
        "No continente africano",
        "No continente europeu",
        "No continente americano",
        "No continente asiático"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "No continente asiático"
      ],
      "score": 10
    },
    {
      "question": "Foi atribuído a Nelson Mandela face à luta que travou contra o apartheid, na África do Sul",
      "choices": [
        "O Nobel de economia",
        "O prêmio de Camões",
        "O Nobel da paz",
        "O oscar honorário"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Nobel da paz"
      ],
      "score": 10
    },
    {
      "question": "A sigla OTAN, em português significa organização do tratado do atlântico norte. Em inglês, ela é chamada NATO, que significa",
      "choices": [
        "North American Treatment Organization",
        "North Atlantic Treasure and Organization",
        "North Atlantic Treaty Organization",
        "North American Treaty and Organization"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "North Atlantic Treaty Organization"
      ],
      "score": 10
    },
    {
      "question": "O moliceiro é um barco característico",
      "choices": [
        "Do rio douro",
        "Do rio Tejo",
        "Do rio Sado",
        "Da ria de Aveiro"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Da ria de Aveiro"
      ],
      "score": 10
    },
    {
      "question": "A monarquia em Portugal terminou em",
      "choices": [
        "1890",
        "1900",
        "1926",
        "1910"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1910"
      ],
      "score": 10
    },
    {
      "question": "Os naturais de Santarém são",
      "choices": [
        "Santarenenses",
        "Albicastrenses",
        "Vimarenenses",
        "Escalabitanos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Escalabitanos"
      ],
      "score": 10
    },
    {
      "question": "O pelourinho é",
      "choices": [
        "O secretário do detentor de um pelouro autárquico",
        "O local mais alto de uma cidade",
        "O detentor de um pelouro autárquico",
        "O local da cidade onde antigamente eram amarrados os delinquentes e outros criminosos, a fim de serem exemplo de justiça"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O local da cidade onde antigamente eram amarrados os delinquentes e outros criminosos, a fim de serem exemplo de justiça"
      ],
      "score": 10
    },
    {
      "question": "O atentado à maratona de Boston ocorreu em 2013. O FBI concluiu que",
      "choices": [
        "Os responsáveis identificados eram do sexo masculino, primos e as bombas explodiram junto à linha de partida da prova",
        "Um dos responsáveis identificados era do sexo masculino e outro do sexo feminino, irmãos e as bombas explodiram junto à linha da chegada da prova",
        "Os responsáveis identificados eram do sexo masculino, amigos, e as bombas explodiram a meio do percurso da prova",
        "Os responsáveis identificados eram do sexo masculino, irmãos e as bombas explodiram junto à linha de chegada da prova"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os responsáveis identificados eram do sexo masculino, irmãos e as bombas explodiram junto à linha de chegada da prova"
      ],
      "score": 10
    },
    {
      "question": "O monumento conhecido como a Sagrada Família está localizado em",
      "choices": [
        "Roma",
        "Londres",
        "Barcelona",
        "Atenas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Barcelona"
      ],
      "score": 10
    },
    {
      "question": "A capital da Hungria é",
      "choices": [
        "Praga",
        "Budapeste",
        "Roma",
        "Zagreb"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Budapeste"
      ],
      "score": 10
    },
    {
      "question": "Qual destas mulheres não ocupou a presidência de um país sul-americano?",
      "choices": [
        "Dilma Rousseff",
        "Cristina Kirchner",
        "Michelle Bachelet",
        "Corazon Aquino"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Corazon Aquino"
      ],
      "score": 10
    },
    {
      "question": "A constituição da república portuguesa de 1976 (atual constituição portuguesa) foi redigida",
      "choices": [
        "Pela assembleia constituinte nomeada",
        "Por um comitê de peritos",
        "Pelo governo da república",
        "Pela assembleia constituinte eleita"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pela assembleia constituinte eleita"
      ],
      "score": 10
    },
    {
      "question": "A assembleia da república tem",
      "choices": [
        "O mínimo de 200 e o máximo de 230 deputados, nos termos da lei eleitoral",
        "O máximo de 250 deputados, nos termos da lei eleitoral",
        "O mínimo de 180 e o máximo de 280 deputados, nos termos da lei eleitoral",
        "O mínimo de 180 e o máximo de 230 deputados, nos termos da lei eleitoral"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O mínimo de 180 e o máximo de 230 deputados, nos termos da lei eleitoral"
      ],
      "score": 10
    },
    {
      "question": "A legislatura da assembleia da república tem duração de",
      "choices": [
        "5 sessões legislativas",
        "4 sessões legislativas",
        "4 anos civis",
        "5 anos civis"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "4 sessões legislativas"
      ],
      "score": 10
    },
    {
      "question": "O governo é constituído",
      "choices": [
        "Pelo primeiro-ministro, pelos ministros e pelos secretários e subsecretários de estado",
        "Pelos ministros e pelos secretários e subsecretários de estado",
        "Pelo primeiro ministro e pelos secretários e subsecretários de estado",
        "Pelo primeiro ministro e subsecretários de estado"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pelo primeiro-ministro, pelos ministros e pelos secretários e subsecretários de estado"
      ],
      "score": 10
    },
    {
      "question": "Nos termos do artigo 14º da DUDH todo ser humano, vítima de perseguição tem o direito de procurar e de gozar de asilo em outros países, exceto por crimes",
      "choices": [
        "De direito comum ou por todos os atos contrários aos objetivos e princípios das Nações Unidas",
        "Políticos",
        "Cometidos contra Estados ditatoriais com o fim de os derrubar",
        "De direito comum ou por atos segundo os objetivos e princípios das Nações Unidas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De direito comum ou por todos os atos contrários aos objetivos e princípios das Nações Unidas"
      ],
      "score": 10
    },
    {
      "question": "Nos termos do artigo 23º da DUDH todo ser humano que trabalha tem direito",
      "choices": [
        "A uma remuneração justa e satisfatória que lhe assegure, assim como à sua família, uma existência compatível com a sua condição social",
        "A uma remuneração justa e satisfatória que lhe assegure, assim como à sua família uma existência que lhe permita no mínimo alimentar-se",
        "A uma remuneração justa e satisfatória que lhe assegure, assim como à sua família, uma existência compatível com os padrões sociais mais elevados da sociedade",
        "A uma remuneração justa e satisfatória que lhe assegure, assim como à sua família, uma existência compatível com a dignidade humana"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A uma remuneração justa e satisfatória que lhe assegure, assim como à sua família, uma existência compatível com a dignidade humana"
      ],
      "score": 10
    },
    {
      "question": "Podemos considerar antecessor da DUDH o cilindro",
      "choices": [
        "De Ciro",
        "Do imperador César Augustus",
        "Do imperador Napoleão Bonaparte",
        "De Viriato da Lusitânia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De Ciro"
      ],
      "score": 10
    },
    {
      "question": "Ao longo dos anos a organização da PSP sofreu algumas alterações com o intuito de acompanhar a evolução da sociedade portuguesa. Das afirmações que se seguem indique qual corresponde à atual organização da PSP:",
      "choices": [
        "PSP compreende um comando geral, os comandos metropolitanos, os comandos regionais, os comandos de polícia, o comando das forças especiais e os estabelecimentos de ensino",
        "A PSP compreende o comando geral e as polícias distritais",
        "A PSP compreende a direção nacional, as unidades de polícia e os estabelecimentos de ensino policial",
        "A PSP compreende direção nacional, os comandos metropolitanos, regionais e de polícia, o corpo de intervenção, o grupo de operações especiais, o corpo de segurança pessoal, o instituto superior de ciências policiais e segurança interna e escola prática de polícia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A PSP compreende a direção nacional, as unidades de polícia e os estabelecimentos de ensino policial"
      ],
      "score": 10
    },
    {
      "question": "A PSP depende do membro do governo responsável pela",
      "choices": [
        "Área da administração interna e a sua organização é única para todo o território nacional",
        "Área da administração interna conjuntamente com a área da justiça e a sua organização é única para todo o território nacional",
        "Área da justiça e a sua organização é única para todo o território nacional",
        "Área da administração interna e a sua organização é dividida em duas áreas territoriais- urbana e rural"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Área da administração interna e a sua organização é única para todo o território nacional"
      ],
      "score": 10
    },
    {
      "question": "Em situações de normalidade institucional, as atribuições da PSP são as decorrentes da legislação de segurança interna. Constituem atribuições da PSP",
      "choices": [
        "Garantir a ordem e a tranquilidade públicas e a segurança e os interesses particulares das pessoas e dos bens",
        "Prevenir a criminalidade em geral, coordenando todas as demais forças e serviços de segurança",
        "Garantir as condições de segurança que permitam o exercício dos direitos e liberdades e o respeito pelas garantias dos cidadãos, bem como o pleno funcionamento das instituições democráticas no respeito pela legalidade e pelos princípios do estado de direito",
        "Desenvolver todas as ações de investigação criminal e contra-ordenacional que lhe sejam denunciadas, em total autonomia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Garantir as condições de segurança que permitam o exercício dos direitos e liberdades e o respeito pelas garantias dos cidadãos, bem como o pleno funcionamento das instituições democráticas no respeito pela legalidade e pelos princípios do estado de direito"
      ],
      "score": 10
    },
    {
      "question": "A direção nacional compreende",
      "choices": [
        "O diretor nacional, os diretores nacionais adjuntos, o conselho superior de polícia, o conselho de deontologia e disciplina a junta superior de saúde e a inspeção",
        "O diretor nacional, os diretores nacionais adjuntos, a inspeção, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças",
        "O diretor nacional, os diretores nacionais adjuntos, o conselho superior de policia, o conselho de deontologia e disciplina, a junta superior de saúde, a inspeção, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças",
        "O diretor nacional, os diretores nacionais adjuntos, o conselho superior de policia, o conselho de deontologia e disciplina, a inspeção, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O diretor nacional, os diretores nacionais adjuntos, o conselho superior de policia, o conselho de deontologia e disciplina, a junta superior de saúde, a inspeção, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças"
      ],
      "score": 10
    },
    {
      "question": "O diretor nacional da PSP é coadjuvado por",
      "choices": [
        "4 diretores nacionais adjuntos, que dirigem respetivamente, as unidades orgânicas de operações e segurança, de recursos humanos, de logística e finanças e a inspeção",
        "3 diretores nacionais adjuntos, que dirigem respetivamente, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças",
        "2 diretores nacionais adjuntos, que dirigem respetivamente, as unidades orgânicas de operações e segurança e de recursos humanos",
        "3 diretores nacionais adjuntos, que dirigem respetivamente, o conselho superior de policia, o conselho de deontologia e disciplina e a junta superior de saúde"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3 diretores nacionais adjuntos, que dirigem respetivamente, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças"
      ],
      "score": 10
    },
    {
      "question": "O diretor da PSP é substituído nas suas faltas ou impedimentos",
      "choices": [
        "Pelo diretor nacional adjunto que dirige a unidade orgânica de operações e segurança",
        "Pelo diretor nacional adjunto que dirige a unidade orgânica de recursos humanos",
        "Pelo diretor nacional adjunto que dirige a unidade orgânica de logística e finanças",
        "Pelo diretor nacional adjunto que dirige o conselho superior de policia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pelo diretor nacional adjunto que dirige a unidade orgânica de operações e segurança"
      ],
      "score": 10
    },
    {
      "question": "A escola prática de polícia é um estabelecimento de ensino policial, na dependência do diretor nacional, que tem por missão",
      "choices": [
        "Formar oficiais de policia, promover o seu aperfeiçoamento permanente e realizar, coordenar ou colaborar em projetos de investigação do domínio das ciências policiais",
        "Ministrar cursos e estágios de formação, aperfeiçoamento e atualização de agentes, chefes e oficiais, e de especialização para todo o pessoal da PSP",
        "Formar oficiais de policia, ministrar cursos e estágios de formação, aperfeiçoamento e atualização de agentes e chefes. Ministrar cursos e estágios de formação, aperfeiçoamento e atualização de agentes e chefes, e de especialização para todo o pessoal da PSP",
        "Ministrar cursos e estágios de formação aperfeiçoamento e atualização de agentes e chefes, e de especialização para todo o pessoal da PSP"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ministrar cursos e estágios de formação aperfeiçoamento e atualização de agentes e chefes, e de especialização para todo o pessoal da PSP"
      ],
      "score": 10
    },
    {
      "question": "A unidade especial de polícia (UEP) compreende as seguintes subunidades operacionais:",
      "choices": [
        "O corpo de intervenção, o grupo de operações especiais, o corpo de segurança pessoal, o grupo de intervenção de proteção e socorro e o grupo operacional cinotécnico",
        "O corpo de intervenção, o grupo de operações especiais, o corpo de segurança pessoal, o centro de inativação de explosivos e segurança em subsolo e o grupo operacional cinotécnico",
        "O corpo de intervenção, o grupo de operações especiais, o serviço de estrangeiros e fronteiras, o centro de inativação de explosivos e segurança em subsolo e o grupo operacional cinotécnico",
        "O corpo de intervenção, o grupo de operações especiais, o serviço de estrangeiros e fronteiras, o centro de inativação de explosivos e segurança em subsolo e a unidade de controlo costeiro"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O corpo de intervenção, o grupo de operações especiais, o corpo de segurança pessoal, o centro de inativação de explosivos e segurança em subsolo e o grupo operacional cinotécnico"
      ],
      "score": 10
    },
    {
      "question": "O corpo de intervenção (CI) constitui uma força reservada à ordem do diretor nacional, especialmente preparada e destinada a ser utilizada",
      "choices": [
        "Em ações de manutenção e reposição da ordem pública",
        "Para a segurança pessoal de altas entidades, membros de órgãos de soberania, proteção policial de testemunhas ou outros cidadãos sujeitos a ameaça",
        "Para combater situações de violência declarada cuja resolução ultrapasse os meios normais de atuação",
        "Especialmente na aplicação de canídeos no quadro de competências da PSP"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Em ações de manutenção e reposição da ordem pública"
      ],
      "score": 10
    },
    {
      "question": "Os policias estão integrados por ordem decrescente de hierarquia nas seguintes carreiras e categoriais:",
      "choices": [
        "Oficial de policia, que compreende as categorias de superintendente, intendente, subintendente e comissário, chefe de policia, que compreende as categorias chefe coordenador, chefe principal e chefe e agente de policia, que compreende as seguintes categorias de agente coordenador, agente principal e agente",
        "Oficial de policia que compreende as categorias de superintendente-chefe, superintendente intendente e subcomissário, chefe de policia que compreende as categorias chefe principal e chefe e agente de policia que compreende as categorias de agente principal e agente",
        "Oficial de policia, que compreende as categorias de superintendente-chefe superintendente intendente subintendente comissario e subcomissário, chefe de policia que compreende as categorias chefe coordenador, chefe principal e chefe e agente de policia que compreende as categorias de agente coordenador, agente principal e agente",
        "Oficial de policia, que compreende as categorias de superintendente, intendente, subintendente, comissario e subcomissário, chefe de policia que compreende as categorias chefe coordenador, chefe principal e chefe e agente de policia, que compreende as categorias de agente principal e agente"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Oficial de policia, que compreende as categorias de superintendente-chefe superintendente intendente subintendente comissario e subcomissário, chefe de policia que compreende as categorias chefe coordenador, chefe principal e chefe e agente de policia que compreende as categorias de agente coordenador, agente principal e agente"
      ],
      "score": 10
    },
    {
      "question": "São definitivamente nomeados na categoria de agente os alunos habilitados com o curso de formação de agentes. Após a conclusão do mesmo o policia é sujeito a uma avaliação a ter lugar em período experimental com a duração de",
      "choices": [
        "3 anos e o regime de avaliação do período experimental da nomeação dos policias é aprovado por despacho do ministro da administração interna",
        "2 anos e o regime de avaliação do período experimental da nomeação dos policias é aprovado por despacho do diretor nacional",
        "Um ano e o regime de avaliação do período experimental da nomeação dos policias é aprovado por despacho do diretor nacional",
        "5 anos e o regime de avaliação do período experimental da nomeação dos policias é aprovado por despacho do ministro da administração interna sob proposta do diretor nacional"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Um ano e o regime de avaliação do período experimental da nomeação dos policias é aprovado por despacho do diretor nacional"
      ],
      "score": 10
    },
    {
      "question": "São condições de promoção para a categoria de agente principal",
      "choices": [
        "Ter pelo menos o tempo mínimo de 4 anos de serviço efetivo na categoria de agente e estar na classe de comportamento exemplar ou na 1ª classe de comportamento e ter frequentado, na categoria, um mínimo de 90 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP",
        "Ter pelo menos o tempo mínimo de 6 anos de serviço efetivo na categoria de agente e estar na classe de comportamento exemplar ou na 1ª classe de comportamento e ter frequentado, na categoria, um mínimo de 90 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP",
        "Ter pelo menos o tempo mínimo de 6 anos de serviço efetivo na categoria e agente e estar na classe de comportamento exemplar ou na 1ª ou 2ª classe de comportamento e ter frequentado, na categoria, um mínimo de 90 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP",
        "Ter pelo menos o tempo mínimo de 8 anos de serviço efetivo na categoria de agente e estar na classe de comportamento exemplar ou na 1ª ou 2ª classe de comportamento e ter frequentado, na categoria, um mínimo de 90 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ter pelo menos o tempo mínimo de 6 anos de serviço efetivo na categoria de agente e estar na classe de comportamento exemplar ou na 1ª classe de comportamento e ter frequentado, na categoria, um mínimo de 90 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP"
      ],
      "score": 10
    },
    {
      "question": "Quanto ao uso e porte de arma, os policias",
      "choices": [
        "Não têm direito ao uso e porte de armas e munições de qualquer tipo, mesmo que se sujeitem a um plano de formação e de certificação constituído por provas teóricas e práticas de tiro",
        "Têm direito ao uso e porte de armas e munições de qualquer tipo, desde que as adquiram num armeiro, mas estão sujeitos a um plano de formação e de certificação constituído por provas teóricas e práticas de tiro",
        "Têm direito ao uso e porte de armas e munições de qualquer tipo, desde que distribuídas pelo estado, e estão sujeitos a um plano de formação e de certificação constituído por provas teóricas e práticas de tiro",
        "Têm direito ao uso e porte de armas e munições de qualquer tipo, desde que distribuídas pelo Estado e não estão sujeitos a um plano de formação e de certificação de tiro"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Têm direito ao uso e porte de armas e munições de qualquer tipo, desde que distribuídas pelo estado, e estão sujeitos a um plano de formação e de certificação constituído por provas teóricas e práticas de tiro"
      ],
      "score": 10
    },
    {
      "question": "O parlamento europeu é composto por representantes",
      "choices": [
        "Dos cidadãos da união",
        "Dos estados da união",
        "Dos governos dos estados da união",
        "Das instituições da união"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Dos cidadãos da união"
      ],
      "score": 10
    },
    {
      "question": "O conselho europeu é composto",
      "choices": [
        "Pelos chefes de estado ou de governo dos estados membros, bem como pelo seu presidente e pelo presidente da comissão",
        "Apenas pelos chefes de estado dos estados membros",
        "Pelos ministros dos negócios estrangeiros dos estados membros",
        "Pelos ministros dos negócios estrangeiros dos estados membros, bem como pelo seu presidente e pelo presidente da comissão"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pelos chefes de estado ou de governo dos estados membros, bem como pelo seu presidente e pelo presidente da comissão"
      ],
      "score": 10
    }
  ],
}
