import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AppBar, Toolbar, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useQuiz } from '../../context/QuizContext';
import { ScreenTypes } from '../../types';

import QuestionScreen from '../QuestionScreen';
import QuizDetailsScreen from '../QuizDetailsScreen';
import QuizTopicsScreen from '../QuizTopicsScreen/psp'; ///AQUI PARA REPLICAR
import ResultScreen from '../ResultScreen';
import SplashScreen from '../SplashScreen';
import LogoutButton from '../LogoutButton';
import ToggleTheme from '../ui/ToggleTheme';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
`;

const ContentContainer = styled.div`
  margin-top: 50px; /* Espaçamento abaixo da barra de navegação */
  width: 100%;
`;

interface MainProps {
  currentTheme: string;
  toggleTheme: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Main: React.FC<MainProps> = ({ currentTheme, toggleTheme }) => {
  const { currentScreen, setCurrentScreen } = useQuiz();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setCurrentScreen(ScreenTypes.QuizTopicsScreen);
    }, 1000);
  }, [setCurrentScreen]);

  const handlePurchaseNavigation = () => {
    navigate('/purchase');
  };

  const screenComponents = {
    [ScreenTypes.SplashScreen]: <SplashScreen />,
    [ScreenTypes.QuizTopicsScreen]: <QuizTopicsScreen />,
    [ScreenTypes.QuizDetailsScreen]: <QuizDetailsScreen />,
    [ScreenTypes.QuestionScreen]: <QuestionScreen />,
    [ScreenTypes.ResultScreen]: <ResultScreen />,
  };

  const ComponentToRender = screenComponents[currentScreen] || <SplashScreen />;

  return (
    <MainContainer>
      <ContentContainer>
        {ComponentToRender}
      </ContentContainer>
    </MainContainer>
  );
}

export default Main;
