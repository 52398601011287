import React from 'react';
import styled from 'styled-components';
import { auth } from '../firebase/firebaseConfig';
import updatePurchaseStatus from '../firebase/updatePurchaseStatus';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centraliza o conteúdo verticalmente */
  padding: 20px;
  text-align: center;
  min-height: 100vh; /* Garante que o contêiner ocupe toda a altura da tela */
  margin-top: 80px; /* Adiciona espaço no topo para evitar sobreposição da navbar */
`;

const StyledTypography = styled(Typography)`
  margin-bottom: px; /* Ajusta o espaço abaixo do título */
`;

const FeatureTableWrapper = styled.div`
  width: 80%; /* Ajuste conforme necessário */
  background-color: rgba(255, 255, 255, 0.8); /* Cor branca com opacidade de 0.8 */
  padding: 20px;
  border-radius: 8px; /* Adiciona cantos arredondados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra */
  display: flex;
  justify-content: center;
`;

const FeatureTable = styled.table`
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  td {
    text-align: center;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const PayPalButtonWrapper = styled.div`
  margin-top: 50px;
  transform: scale(0.9); /* Reduz o tamanho do botão do PayPal */
  transform-origin: center; /* Mantém o botão centralizado após a escala */
`;

const Purchase = () => {
  const navigate = useNavigate();

  const handleApprove = async (data, actions) => {
    try {
      const userId = auth.currentUser.uid;

      // Atualiza o status de compra no Firestore
      await updatePurchaseStatus(userId);

      alert("Compra realizada com sucesso!");

      // Redirecionar para o dashboard após a compra
      navigate('/dashboard');
    } catch (error) {
      console.error("Erro ao processar a compra: ", error);
    }
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <StyledContainer>
      <StyledTypography variant="h4" gutterBottom>
        Acesso Premium
      </StyledTypography>
      <FeatureTableWrapper>
        <FeatureTable>
          <thead>
            <tr>
              <th>Recursos</th>
              <th>Gratuito</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Acesso a quizzes básicos</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>Acesso a quizzes avançados</td>
              <td>❌</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>Relatórios detalhados</td>
              <td>❌</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>Suporte prioritário</td>
              <td>❌</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>Sem anúncios</td>
              <td>❌</td>
              <td>✔️</td>
            </tr>
          </tbody>
        </FeatureTable>
      </FeatureTableWrapper>
      <PayPalButtonWrapper>
        <PayPalScriptProvider options={{ "client-id": "AXuRTehxFJQpNRYLZ1_dEpxJFupqqU6Ua0bykcO7YzkBkvU7kn6wHtYdgu688TF5Ro6X4ciywx__qwz9" }}>
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: "2.99" // Mude para o valor apropriado
                  }
                }]
              });
            }}
            onApprove={handleApprove}
            onError={(err) => {
              console.error("Erro no pagamento: ", err);
              alert("Ocorreu um erro ao processar seu pagamento. Por favor, tente novamente.");
            }}
          />
        </PayPalScriptProvider>
      </PayPalButtonWrapper>
      <Button onClick={handleBackToHome} style={{ marginTop: '50px' }}>
        Cancelar
      </Button>
    </StyledContainer>
  );
};

export default Purchase;
