import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Main from '../Main/psp';
import QuizProvider from '../../context/QuizContext';
import { GlobalStyles } from '../../styles/Global';
import { themes } from '../../styles/Theme';
import { AuthProvider } from '../../context/AuthContext';
import PrivateRoute from '../../context/PrivateRoute';


function Psp() {
  const [currentTheme, setCurrentTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'light';
  });

  const toggleTheme = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setCurrentTheme(checked ? 'dark' : 'light');
    localStorage.setItem('theme', checked ? 'dark' : 'light');
  };

  const theme = currentTheme === 'light' ? themes.light : themes.dark;

  return (
    <Main currentTheme={currentTheme} toggleTheme={toggleTheme} />
              
  );
}

export default Psp;
