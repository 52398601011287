import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppLogo } from '../../config/icons';
import { useQuiz } from '../../context/QuizContext';
import { getQuizzes, QuizTopic } from '../../data/quizTopics';
import { device } from '../../styles/BreakPoints';
import {
  CenterCardContainer,
  HighlightedText,
  LogoContainer,
  PageCenter,
} from '../../styles/Global';
import { ScreenTypes } from '../../types';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Heading = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
`;

const DetailText = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
`;

const SelectButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 60%;
  gap: 30px;
  margin-top: 40px;
  margin-bottom: 45px;
  @media ${device.md} {
    row-gap: 20px;
    column-gap: 20px;
    max-width: 100%;
  }
`;

interface SelectButtonProps {
  active: boolean;
  disabled?: boolean;
}

const SelectButton = styled.div<SelectButtonProps>`
  background-color: ${({ disabled, theme }) =>
    disabled ? `${theme.colors.disabledCard}` : `${theme.colors.selectTopicBg}`};
  border: ${({ active, theme }) =>
    active
      ? `1px solid ${theme.colors.themeColor}`
      : `1px solid ${theme.colors.disabledButton}`};
  transition: background-color 0.4s ease-out;
  border-radius: 10px;
  padding: 14px 10px;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  @media ${device.md} {
    padding: 10px;
    tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }
  outline: none; /* Remove default browser outline */
  &:focus {
    outline: none; /* Remove outline on focus */
  }
`;

const SelectButtonText = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  @media ${device.md} {
    font-size: 16px;
    font-weight: 500;
  }
`;

const QuizTopicsScreen: React.FC = () => {
  const { quizTopic, selectQuizTopic, setCurrentScreen } = useQuiz();
  const [loading, setLoading] = useState(true);
  const [quizzes, setQuizzes] = useState<{ geral_quiz: QuizTopic[]; psp_quiz_pc: QuizTopic[] }>({
    geral_quiz: [],
    psp_quiz_pc: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    const loadQuizzes = async () => {
      const fetchedQuizzes = await getQuizzes();
      setQuizzes(fetchedQuizzes);
      setLoading(false);
    };

    loadQuizzes();
  }, []);

  const goToQuizDetailsScreen = (title: string) => {
    selectQuizTopic(title);
    setCurrentScreen(ScreenTypes.QuizDetailsScreen);
  };

  const handlePurchaseNavigation = () => {
    navigate('/purchase');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <PageCenter light justifyCenter>
      <CenterCardContainer>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <Heading>

          Prova de Conhecimentos
          {/* WELCOME TO <HighlightedText> PSP TESTE</HighlightedText> */}
        </Heading>
        


        <DetailText>PSP</DetailText>
        <SelectButtonContainer>
          {quizzes.psp_quiz_pc.map(({ title, icon, disabled }) => (
            <SelectButton
              key={title}
              active={quizTopic === title}
              onClick={() => !disabled && goToQuizDetailsScreen(title)}
              disabled={disabled}
            >
              {icon}
              <SelectButtonText>{title}</SelectButtonText>
            </SelectButton>
          ))}
        </SelectButtonContainer>

        
      </CenterCardContainer>
    </PageCenter>
  );
};

export default QuizTopicsScreen;
