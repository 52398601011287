// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const psp_estatutos: Topic = {
  topic: 'Estatutos - PSP',
  level: 'Advanced',
  totalQuestions: 60,
  totalScore: 300,
  totalTime: 360,
  questions: [
    {
      "question": "A condição policial caracteriza-se:",
      "choices": [
        "Pela sujeição a um regulamento estipulado pelo Ministério da Administração Interna",
        "Pela subordinação à hierarquia de comando no Ministério",
        "Pela subordinação ao interesse público",
        "Pela subordinação ao interesse do Presidente da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pela subordinação ao interesse público"
      ],
      "score": 10
    },
    {
      "question": "Os polícias devem dedicar-se ao serviço com:",
      "choices": [
        "Dedicação, zelo, competência, integridade de caráter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional",
        "Aprumo, zelo, competência, integridade de caráter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional",
        "Convicção, zelo, competência, integridade de caráter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional",
        "Lealdade, zelo, competência, integridade de caráter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Lealdade, zelo, competência, integridade de caráter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional"
      ],
      "score": 10
    },
    {
      "question": "Os polícias devem ter residência habitual na localidade onde predominantemente prestam serviço ou:",
      "choices": [
        "Em local que diste até 40 km daquela",
        "Em local que diste até 50 km daquela",
        "Em local que diste até 60 km daquela",
        "Em local que diste até 55 km daquela"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Em local que diste até 55 km daquela"
      ],
      "score": 10
    },
    {
      "question": "São deveres especiais dos polícias:",
      "choices": [
        "Atuar sem discriminação em razão de ascendência, sexo, raça, língua, território de origem, religião, convicções políticas ou ideológicas, instrução, situação econômica, condição social ou orientação sexual",
        "Exibir previamente prova da sua qualidade quando, uniformizados, aplicarem medidas de polícia ou emitirem qualquer ordem ou mandado legítimo",
        "Atuar com discriminação em razão de ascendência, sexo, raça, língua, território de origem, religião, convicções políticas ou ideológicas, instrução, situação econômica, condição social ou orientação sexual",
        "Nenhuma das hipóteses está correta"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Atuar sem discriminação em razão de ascendência, sexo, raça, língua, território de origem, religião, convicções políticas ou ideológicas, instrução, situação econômica, condição social ou orientação sexual"
      ],
      "score": 10
    },
    {
      "question": "O período anual de férias tem a duração de:",
      "choices": [
        "21 dias úteis",
        "20 dias úteis",
        "18 dias úteis",
        "22 dias úteis"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "22 dias úteis"
      ],
      "score": 10
    },
    {
      "question": "As modalidades de licenças sem remuneração compreendem",
      "choices": [
        "Licença sem remuneração de média duração",
        "Licença sem remuneração de longa duração",
        "Licença sem remuneração para acompanhamento de cônjuge falecido",
        "Licença sem remuneração para o exercício de funções na União Europeia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Licença sem remuneração de longa duração"
      ],
      "score": 10
    },
    {
      "question": "O serviço da PSP é:",
      "choices": [
        "De caráter exclusivo e de serviço ao cidadão",
        "De caráter obrigatório e rotativo",
        "De caráter permanente e obrigatório",
        "De serviço ao público"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De caráter permanente e obrigatório"
      ],
      "score": 10
    },
    {
      "question": "O regime de turnos é:",
      "choices": [
        "Obrigatório, quando o trabalho é prestado nos sete dias da semana",
        "Semanal prolongado, quando é prestado em todos os cinco dias úteis e no sábado ou em feriados",
        "Semanal, quando é prestado de segunda-feira a domingo",
        "Nenhuma das hipóteses está correta"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Nenhuma das hipóteses está correta"
      ],
      "score": 10
    },
    {
      "question": "Qual das alíneas não se encontra correta? Os polícias estão integrados, por ordem decrescente de hierarquia, nas seguintes carreiras e categorias:",
      "choices": [
        "Oficial de polícia, que compreende as categorias de superintendente-chefe, superintendente, intendente, subintendente, comissário e subcomissário",
        "Chefe de polícia, que compreende as categorias chefe coordenador, chefe principal e chefe",
        "Agente de polícia, que compreende as categorias de agente coordenador, agente principal e agente",
        "Agente provisório, sendo admitido ao curso de formação de agentes"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Agente provisório, sendo admitido ao curso de formação de agentes"
      ],
      "score": 10
    },
    {
      "question": "Os polícias podem encontrar-se numa das seguintes situações:",
      "choices": [
        "De licença",
        "Aposentados",
        "Adido",
        "Fora do Ativo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Aposentados"
      ],
      "score": 10
    },
    {
      "question": "Qual a posição a nível remuneratório de um Agente provisório?",
      "choices": [
        "Nível 1",
        "Nível 3",
        "Nível 4",
        "Nível 2"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Nível 3"
      ],
      "score": 10
    },
    {
      "question": "O período anual de férias tem a duração de quantos dias úteis?",
      "choices": [
        "21",
        "22",
        "23",
        "24"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "22"
      ],
      "score": 10
    },
    {
      "question": "Relativamente ao período de férias acresce um dia útil de férias por cada quantos anos de serviço efetivamente prestado?",
      "choices": [
        "5",
        "10",
        "15",
        "20"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "10"
      ],
      "score": 10
    },
    {
      "question": "Quem pode proceder à alteração do período de férias por conveniência de serviço?",
      "choices": [
        "Diretor Nacional",
        "Inspetor nacional",
        "Membro do governo responsável pela área da Administração interna",
        "Diretores nacionais adjuntos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Diretor Nacional"
      ],
      "score": 10
    },
    {
      "question": "Que tipo de faltas existem?",
      "choices": [
        "Justificadas e não justificadas",
        "Justificadas e injustificadas",
        "Justificadas",
        "Não justificadas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Justificadas e injustificadas"
      ],
      "score": 10
    },
    {
      "question": "Com que antecedência é comunicada uma falta?",
      "choices": [
        "2 dias úteis",
        "2 dias",
        "5 dias úteis",
        "5 dias"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5 dias úteis"
      ],
      "score": 10
    },
    {
      "question": "De quem é a competência para a concessão da Licença sem remuneração de curta duração?",
      "choices": [
        "Diretor Nacional",
        "Membro do governo responsável pela área da Administração interna",
        "Comandante da UEP",
        "Diretores nacionais adjuntos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Diretor Nacional"
      ],
      "score": 10
    },
    {
      "question": "De quem é a competência para a concessão da Licença sem remuneração de longa duração?",
      "choices": [
        "Diretor Nacional",
        "Membro do governo responsável pela área da Administração interna",
        "Comandante da UEP",
        "Diretores nacionais adjuntos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Membro do governo responsável pela área da Administração interna"
      ],
      "score": 10
    },
    {
      "question": "Por quanto tempo máximo é concedida a licença sem remuneração de curta duração?",
      "choices": [
        "1 mês",
        "6 meses",
        "1 ano",
        "2 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1 ano"
      ],
      "score": 10
    },
    {
      "question": "Um oficial pode pedir a concessão licença sem remuneração de longa duração após quantos anos de ingresso na PSP?",
      "choices": [
        "1 ano",
        "2 anos",
        "5 anos",
        "10 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "10 anos"
      ],
      "score": 10
    },
    {
      "question": "Carga horária semanal de um agente da PSP?",
      "choices": [
        "32 horas",
        "36 horas",
        "40 horas",
        "44 horas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "36 horas"
      ],
      "score": 10
    },
    {
      "question": "O ingresso na carreira de oficial de polícia faz se em que categoria?",
      "choices": [
        "Comissário",
        "Subcomissário",
        "Intendente",
        "Superintendente"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Subcomissário"
      ],
      "score": 10
    },
    {
      "question": "De quem é a competência os despachos da promoção?",
      "choices": [
        "Diretor Nacional",
        "Membro do governo responsável pela área da Administração interna",
        "Comandante da UEP",
        "Diretores nacionais adjuntos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Membro do governo responsável pela área da Administração interna"
      ],
      "score": 10
    },
    {
      "question": "Em que série são publicados os extratos dos despachos das promoções no DR?",
      "choices": [
        "1ª",
        "2ª",
        "3ª",
        "4ª"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "2ª"
      ],
      "score": 10
    },
    {
      "question": "A carreira de oficial de polícia é uma carreira especial de complexidade funcional de que grau?",
      "choices": [
        "1",
        "2",
        "3",
        "4"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3"
      ],
      "score": 10
    },
    {
      "question": "A promoção a comissário é feita mediante procedimento concursal, no qual é necessário no mínimo quantos anos de serviço efetivo na categoria de subcomissário?",
      "choices": [
        "1",
        "2",
        "4",
        "5"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5"
      ],
      "score": 10
    },
    {
      "question": "A promoção a superintendente-chefe é feita mediante procedimento concursal, no qual é necessário no mínimo quantos anos de serviço efetivo na categoria de superintendente?",
      "choices": [
        "1",
        "2",
        "4",
        "5"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "4"
      ],
      "score": 10
    },
    {
      "question": "Em que promoção é necessário estar habilitado com o Curso de Direção e Estratégia Policial (CDEP)?",
      "choices": [
        "Superintendente",
        "Intendente",
        "Superintendente chefe",
        "Subintendente"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Superintendente"
      ],
      "score": 10
    },
    {
      "question": "A carreira de chefe de polícia é uma carreira especial de complexidade funcional de que grau?",
      "choices": [
        "1",
        "2",
        "3",
        "4"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "2"
      ],
      "score": 10
    }
  ],
}
